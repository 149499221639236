<template>
  <!-- Карта-->
  <leaflet-map
      :zoom="zoom"
      :center="center"
      :options="mapOptions"
      @onMapCreated="onMapCreated"
  >

    <!-- Элементы управления-->
    <leaflet-control-group>
      <!-- Базовые слои-->
      <leaflet-base-layer-group :baseLayers="baseLayers"></leaflet-base-layer-group>
    </leaflet-control-group>

    <leaflet-circle-marker
        v-for="(vehicle, index) in vehicles"
        :key="index"
        :latlng="[vehicle.latitude,vehicle.longitude]"
        :radius="5"
        :options="originalOptions"
        :position="'back'"
    >
    </leaflet-circle-marker>
    <leaflet-circle-marker
        v-if="activeVehicle"
        :latlng="[activeVehicle.latitude, activeVehicle.longitude]"
        :radius="6"
        :options="activeOptions"
        :position="'front'"
      >
    </leaflet-circle-marker>

  </leaflet-map>
</template>

<script>

export default {
  // список ТС
  props: [
      'vehicles',
      'zones',
      'marshLine',
      'stops',
      'activeVehicle'
  ],
  computed: {

    // масштаб по умолчанию
    zoom() {
      return this.$store.getters['settings/getZoom'];
    },

    // центр карты по умолчанию
    center() {
      return this.$store.getters['settings/getCenter'];
    },

    // список базовых слоев
    baseLayers() {
      return this.$store.getters['settings/getBaseLayers'];
    },
  },

  data() {
    return {
      // карта
      leaflet: null,
      // настройки карты
      mapOptions: {
        doubleClickZoom: false
      },
      // границы карты
      minLat: 0,
      maxLat: 0,
      minLong: 0,
      maxLong: 0,
      // текущий масштаб
      curZoom: 0,
      // объект со слоями для элемента управления
      baseLayersObj: {},
      // нет контекстного меню остановки
      noContextMenu: {
        contextmenu: false,
      },
      vehicle: {
        latitude: 55.725937300000005,
        longitude: 37.65464410000001,
        angle: 45
      },
      // опции элемента зоны в стандартном режиме
      originalOptions: {
        color: 'blue',
        weight: 0.0,
        opacity: 0.0,
        fill: true,
        fillColor: 'blue',
        fillOpacity: 0.8,
        draggable: false
      },
      activeOptions: {
        color: 'red',
        weight: 0.0,
        opacity: 0.0,
        fill: true,
        fillColor: 'red',
        fillOpacity: 0.8,
        draggable: false
      },
    }
  },

  methods: {

    // вызывается при создании базового слоя
    onBaseLayerCreated(layer) {
      this.$nextTick(() => {
        this.baseLayersObj = {
          ...this.baseLayersObj,
          [layer.title]: layer.origin
        }
      })
    },

    // вызывается при создании карты
    onMapCreated(leaflet) {
      this.leaflet = leaflet;

      // функция определяет границы карты и текущий zoom
      const getMapValues = () => {
        // определяем границы карты
        const bounds = this.leaflet.getBounds();
        const northEast = bounds.getNorthEast();
        const southWest = bounds.getSouthWest();
        this.minLat = southWest.lat;
        this.maxLat =  northEast.lat;
        this.minLong = southWest.lng;
        this.maxLong = northEast.lng;
        // определяем текущий zoom
        this.curZoom = leaflet.getZoom();
      }

      getMapValues();
      leaflet.on('moveend', () => {
        getMapValues();
      });
    },

  },
}
</script>
