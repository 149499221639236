// import sha256 from "sha256";
// import {Base64} from "js-base64";

// // функция возвращает hash пароля
// export function getPasswordHash(login, password) {
//     return sha256(Base64.encode(String(login).toLowerCase() + ":" + password))
// }

// функция превращает дату в строку
export function dateToString(dt = new Date()) {
    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }

    return padStr(dt.getFullYear()) + '-' + padStr(1 + dt.getMonth()) + '-' + padStr(dt.getDate());
}

// функция превращает строку в дату
export function stringToDate(str) {
    return new Date(str)
}

// функция возвращает кол-во дней в месяце
export function daysInMonth(month, year) {
    // при передачи 0 в конструктор как дня, вернется последний день предыдущего месяца
    // поэтому для получения количества дней текущего месяца прибавляем к месяцу +1
    return new Date(year, month + 1, 0).getDate();
}

// функция возвращает начало и конец квартала
export function quartals(date) {
    // т.к. мы рассматриваем кварталы в рамках одного года, определяем год из даты
    let year = date.getFullYear();
    // начало первого квартала (1 января)
    let qOneStart = dateToND(new Date(year, 0, 1,3));
    // конец первого квартала (31 марта)
    let qOneEnd = dateToND(new Date(year, 2, 31,3));
    // начало первого квартала (1 апреля)
    let qTwoStart = dateToND(new Date(year, 3, 1,3));
    // конец первого квартала (30 июня)
    let qTwoEnd = dateToND(new Date(year, 5, 30,3));
    // начало первого квартала (1 июля)
    let qThreeStart = dateToND(new Date(year, 6, 1,3));
    // конец первого квартала (30 сентября)
    let qThreeEnd = dateToND(new Date(year, 8, 30,3));
    // начало первого квартала (1 октября)
    let qFourStart = dateToND(new Date(year, 9, 1,3));
    // конец первого квартала (31 декабря)
    let qFourEnd = dateToND(new Date(year, 11, 31,3));
    // выбранная дата в кол-ве дней с 2020-01-01
    let selectedDate = dateToND(date);

    // проверяем попала ли дата в первый квартал
    if (qOneStart <= selectedDate && selectedDate  <= qOneEnd) {
        return {start: {month: 0, date: 1}, end: {month: 2, date: 31}};
        // проверяем попала ли дата во второй квартал
    } else if (qTwoStart <= selectedDate && selectedDate <= qTwoEnd) {
        return {start: {month: 3, date: 1}, end: {month: 5, date: 30}};
        // проверяем попала ли дата в третий квартал
    } else if (qThreeStart <= selectedDate && selectedDate <= qThreeEnd) {
        return {start: {month: 6, date: 1}, end: {month: 8, date: 30}}
        // проверяем попала ли дата в четвертый квартал
    } else if (qFourStart <= selectedDate && selectedDate <= qFourEnd) {
        return {start: {month: 9, date: 1}, end: {month: 11, date: 30}}
    }
}

// функция возвращает в какой квартал попала дата
export function getQuarters(date) {
    // т.к. мы рассматриваем кварталы в рамках одного года, определяем год из даты
    let year = date.getFullYear();
    // начало первого квартала (1 января)
    let qOneStart = dateToND(new Date(year, 0, 1,3));
    // конец первого квартала (31 марта)
    let qOneEnd = dateToND(new Date(year, 2, 31,3));
    // начало первого квартала (1 апреля)
    let qTwoStart = dateToND(new Date(year, 3, 1,3));
    // конец первого квартала (30 июня)
    let qTwoEnd = dateToND(new Date(year, 5, 30,3));
    // начало первого квартала (1 июля)
    let qThreeStart = dateToND(new Date(year, 6, 1,3));
    // конец первого квартала (30 сентября)
    let qThreeEnd = dateToND(new Date(year, 8, 30,3));
    // начало первого квартала (1 октября)
    let qFourStart = dateToND(new Date(year, 9, 1,3));
    // конец первого квартала (31 декабря)
    let qFourEnd = dateToND(new Date(year, 11, 31,3));
    // выбранная дата в кол-ве дней с 2020-01-01
    let selectedDate = dateToND(date);

    // проверяем попала ли дата в первый квартал
    if (qOneStart <= selectedDate && selectedDate  <= qOneEnd) {
        return 1;
        // проверяем попала ли дата во второй квартал
    } else if (qTwoStart <= selectedDate && selectedDate <= qTwoEnd) {
        return 2;
        // проверяем попала ли дата в третий квартал
    } else if (qThreeStart <= selectedDate && selectedDate <= qThreeEnd) {
        return 3;
        // проверяем попала ли дата в четвертый квартал
    } else if (qFourStart <= selectedDate && selectedDate <= qFourEnd) {
        return 4;
    }
}

// функция возвращает в какой квартал попала дата
export function getQuarterBorders(year, quart) {
    // т.к. мы рассматриваем кварталы в рамках одного года, определяем год из даты
    // начало первого квартала (1 января)
    let qOneStart = dateToND(new Date(year, 0, 1,3));
    // конец первого квартала (31 марта)
    let qOneEnd = dateToND(new Date(year, 2, 31,3));
    // начало первого квартала (1 апреля)
    let qTwoStart = dateToND(new Date(year, 3, 1,3));
    // конец первого квартала (30 июня)
    let qTwoEnd = dateToND(new Date(year, 5, 30,3));
    // начало первого квартала (1 июля)
    let qThreeStart = dateToND(new Date(year, 6, 1,3));
    // конец первого квартала (30 сентября)
    let qThreeEnd = dateToND(new Date(year, 8, 30,3));
    // начало первого квартала (1 октября)
    let qFourStart = dateToND(new Date(year, 9, 1,3));
    // конец первого квартала (31 декабря)
    let qFourEnd = dateToND(new Date(year, 11, 31,3));
    // проверяем попала ли дата в первый квартал
    if (quart == 1) {
        return {start: qOneStart, end: qOneEnd};
        // проверяем попала ли дата во второй квартал
    } else if (quart == 2) {
        return {start: qTwoStart, end: qTwoEnd};
        // проверяем попала ли дата в третий квартал
    } else if (quart == 3) {
        return {start: qThreeStart, end: qThreeEnd}
        // проверяем попала ли дата в четвертый квартал
    } else if (quart == 4) {
        return {start: qFourStart, end: qFourEnd}
    }
}

// функция возвращает текущую дату минус месяц
export function getDateMinusMonth(dt = new Date()) {
    let dayOfTheMonth = dt.getDate();
    dt.setMonth((dt.getMonth() - 1), dayOfTheMonth);
    return dt;
}

// объект возвращает разницу между датами
export const dateDiff = {

    // в миллисекундах
    inMilliseconds: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt(t2 - t1);
    },

    // в секундах
    inSeconds: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / 1000);
    },

    // в минутах
    inMinutes: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (60 * 1000));
    },

    // в часах
    inHours: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (3600 * 1000));
    },

    // в днях
    inDays: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },

    // в неделях
    inWeeks: function (d1, d2) {
        const t2 = d2.getTime();
        const t1 = d1.getTime();

        return parseInt((t2 - t1) / (24 * 3600 * 1000 * 7));
    },

    // в месяцах
    inMonths: function (d1, d2) {
        const d1Y = d1.getFullYear();
        const d2Y = d2.getFullYear();
        const d1M = d1.getMonth();
        const d2M = d2.getMonth();

        return (d2M + 12 * d2Y) - (d1M + 12 * d1Y);
    },

    // в годах
    inYears: function (d1, d2) {
        return d2.getFullYear() - d1.getFullYear();
    }
}

// конвертирует дату в количество дней с 2000-01-01
export function dateToND(dt = new Date()) {
    return dateDiff.inDays(new Date('2000-01-01'), dt)
}

// конвертирует дату в количество секунд с 2000-01-01
export function dateToSeconds(dt = new Date()) {

    let daysInSeconds = dateToND(dt) * 24 * 60 * 60;
    let hours = dt.getHours() * 60 * 60;
    let min = dt.getMinutes() * 60;
    let sec = dt.getSeconds();

    return daysInSeconds + hours + min + sec;
}

// конвертирует количество дней с 2000-01-01 в дату
export function ndToDate(nd) {
    return new Date(new Date('2000-01-01').getTime() + nd * 24 * 3600 * 1000);
}

// конвертирует количество секунд с 2000-01-01 в дату и время
export function secondsToDateAndTime(seconds) {
    // конвертируем секунды в дату
    let date = new Date((seconds + (10957 * 24 * 60 * 60)) * 1000)
    let dateString = dateToString(date)
    // получаем количество дней с 2000-01-01
    let days = dateDiff.inDays(new Date('2000-01-01'), date);
    let time = getTimeWithSecondsFormat(seconds - (days * 24 * 60 * 60))
    return dateString + ' ' + time;
}

// конвертирует количество секунд с 2000-01-01 в дату и время без секнуд
export function secondsToDateAndTimeWithoutSeconds(seconds) {
    // конвертируем секунды в дату
    let date = new Date((seconds + (10957 * 24 * 60 * 60)) * 1000)
    let dateString = dateToString(date)
    // получаем количество дней с 2000-01-01
    let days = dateDiff.inDays(new Date('2000-01-01'), date);
    let time = getTimeFormat(seconds - (days * 24 * 60 * 60))
    return dateString + ' ' + time;
}

// конвертирует количество секунд с 2000-01-01 в дату
export function secondsToDate(seconds) {
    // конвертируем секунды в дату
    let date = new Date((seconds + (10957 * 24 * 60 * 60)) * 1000)
    let dateString = dateToString(date)
    return dateString;
}

// конвертирует количество секунд с 2000-01-01 во время без секунд
export function secondsToTime(seconds) {
    // конвертируем секунды в дату
    let date = new Date((seconds + (10957 * 24 * 60 * 60)) * 1000)
    // получаем количество дней с 2000-01-01
    let days = dateDiff.inDays(new Date('2000-01-01'), date);
    let time = getTimeFormat(seconds - (days * 24 * 60 * 60))
    return time;
}

//Конвертирует и возвращает дату и время из формата ISO 8601 (RFC-3339)
export function getDateTimeFormat(dateAndTime) {
    if (dateAndTime) {
        // "Отделяем" дату
        let result = dateAndTime.substring(0, 10);
        // Прибавляем время через пробел
        result = result + " " + dateAndTime.substring(11, 19);
        //Возвращаем отформатированные дату и время
        return result;
    } else {
        return '';
    }
}

//Конвертирует и возвращает время из формата ISO 8601 (RFC-3339)
export function getDateTimeFormatTimeString(dateAndTime) {
    if (dateAndTime) {
        // Возвращаем отформатированное время
        return dateAndTime.substring(11, 19);
    } else {
        return '';
    }
}

//Конвертирует и возвращает дату из формата ISO 8601 (RFC-3339)
export function getTimeFormatFromDateString(dateAndTime) {
    if (dateAndTime) {
        // "Отделяем" время
        let result = dateAndTime.substring(11, 19);
        return result;
    } else {
        return '';
    }
}

//Конвертирует и возвращает дату
export function getDateStringFromDateTime(dateAndTime) {
    function padStr(i) {
        return (i < 10) ? "0" + i : "" + i;
    }
    if (dateAndTime) {
        let dt = new Date(dateAndTime);
        return dt.getFullYear() + '-' + padStr(1 + dt.getMonth()) + '-' + padStr(dt.getDate());
    } else {
        return '';
    }
}

//Конвертирует и возвращает время без секунд из значения в секундах
export function getTimeFormat(time) {
    if (time != null && time !== '' && Number.isFinite(Number(time))) {
        time = Number(time);
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        //Возвращаем отформатированное время
        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes));
        return result;
    } else {
        return '';
    }
}

//Конвертирует и возвращает дату и время в формате ("dd.MM HH:mm") из значения в милисекундах
export function getStatTimeFormat(time) {
    if (time != null && time !== '' && Number.isFinite(Number(time))) {
        time = Number(time);
        let dt = new Date(time);
        return (padStr(dt.getDate()) + '.' + padStr(1 +dt.getMonth()) + " " + padStr(dt.getHours()) + ':' +  padStr(dt.getMinutes()));
    } else {
        return '';
    }
}

// С сервера приходит дата и время в виде милисекунд, при создании Date в него прописывается timezone,
// соответственно при получении времени из этого Date часы получаеются +timezone, соответственно нужно
// вычесть это добавленное время (GMT+0300 userTimezoneOffset получается -180, поэтому во второй строке "+")
export function getDateWitoutTimeZone(date) {
    let userTimezoneOffset = date.getTimezoneOffset() * 60000;
    return new Date(date.getTime() + userTimezoneOffset);
}

//Конвертирует и возвращает время с секундами из значения в секундах
export function getTimeWithSecondsFormat(time) {
    if (time) {
        // часы
        let hours = Math.trunc(time / 3600);
        // минуты
        let minutes = Math.trunc((time - (hours * 3600)) / 60);
        // секунды
        let seconds = Math.trunc(time - (hours * 3600) - (minutes * 60));

        let result = (hours === 0 ? '00' : hours < 10 ? ('0' + hours) : hours) + ':' + (minutes === 0 ? '00' : (minutes < 10 ? ('0' + minutes) : minutes)) + ':' + (seconds === 0 ? '00' : (seconds < 10 ? ('0' + seconds) : seconds));
        return result;
    } else {
        return '00:00:00';
    }
}

export function padStr(i) {
    return (i < 10) ? "0" + i : "" + i;
}

// Реализует функцию быстрой сортировки
// ARows - массив значений
// SortFn - функция сортировки
export function quickSort(ARows, SortFn, L = 0, R = ARows.length - 1) {
    let I, J;
    let P, T;
    // if (ARows.length == 10) {
    //     console.log('ARows', ARows)
    //     console.log('SortFn', SortFn)
    // }
    // если длина массива меньше 2-х сортировать нечего
    if (ARows.length < 2) return;

    do {
        I = L;
        J = R;
        P = ARows[Math.floor((L + R) / 2)];
        do {
            while (SortFn(ARows[I], P) < 0) I++;
            while (SortFn(ARows[J], P) > 0) J--;

            if (I <= J) {
                if (I !== J) {
                    T = ARows[I];
                    ARows[I] = ARows[J];
                    ARows[J] = T;
                }
                I++;
                J--;
            }

        } while (I <= J);
        if (L < J) {
            quickSort(ARows, SortFn, L, J);
        }
        L = I;
    }
    while (I < R);
}

// Реализует функцию быстрого поиска
// AValue - значение, которое ищем,
// ARows - отсортированный массив значений
// SortFn - функция сортировки
// Возвращает объект {Index: number, Result: boolean)
// Result - значение найдено, Index - индекс с найденным значением
export function quickFind(AValue, ARows, SortFn) {
    let L, H, I, C;
    let Index;
    let Result = false;

    L = 0;
    H = ARows.length - 1;
    while (L <= H) {
        I = Math.floor((L + H) / 2);
        C = SortFn(ARows[I], AValue);
        if (C < 0) {
            L = I + 1;
        } else {
            H = I - 1;
            if (C === 0) {
                Result = true;
            }
        }
    }
    Index = L;
    // возвращаем результат
    return {
        Index,
        Result
    }
}

// функция для сортировки строк
export function strSortFn(str1, str2) {
    if (str1 == null)
        str1 = '';
    if (str2 == null)
        str2 = '';
    str1 = String(str1);
    str2 = String(str2);
    if (str1 < str2) return (-1)
    else if (str1 > str2) return (1)
    else return (0);
}

// функция для сортировки логических значений
export function boolSortFn(b1, b2) {
    if (b1 == null)
        b1 = false;
    if (b2 == null)
        b2 = false;
    b1 = Boolean(b1);
    b2 = Boolean(b2);
    if (b1 < b2) return (-1)
    else if (b1 > b2) return (1)
    else return 0;
}

// // функция для сортировки вариантов маршрута
// export function dateSortFn(rv1, rv2) {
//
//     if (rv1 && rv2) {
//         let d1 = new Date(rv1.date_begin);
//         let d2 = new Date(rv2.date_begin);
//         if (d2 > d1) {
//             return (-1)
//         } else if (d1 > d2) return (1)
//         else return 0;
//     }
//     return 0;
// }

// функция для сортировки цифр
export function numSortFn(num1, num2) {
    if (num1 == null)
        num1 = Number.MIN_SAFE_INTEGER;
    if (num2 == null)
        num2 = Number.MIN_SAFE_INTEGER;
    if (num1 < num2) return (-1)
    else if (num1 > num2) return 1
    else return (0);
}

// функция для сортировки типов рейса по сигнатурам
export function signSortFn(sign1, sign2) {
    if (typeof (sign1) !== 'string')
        sign1 = '';
    if (typeof (sign2) !== 'string')
        sign2 = '';

    // если оба рейса непроизводственные
    if ((sign1.substring(0, 1) == "_" || sign1.substring(0, 1) == "-") && (sign2.substring(0, 1) == "_" || sign2.substring(0, 1) == "-")) {
        // определяем рейс между остановочный или из парка/в парк
        //если первый из парка в парк
        if (sign1.substring(0, 1) == "_" && sign2.substring(0, 1) == "-") {
            return (-1);
        } else if (sign1.substring(0, 1) == "-" && sign2.substring(0, 1) == "_") {
            return (1);
        } else if (sign1.substring(0, 1) === sign2.substring(0, 1)) {
            if (sign1.substring(2) < sign2.substring(2)) return (-1)
            else if (sign1.substring(2) > sign2.substring(2)) return (1)
            else return (0);
        }
        //если второй производственный, а первый нет
    } else if ((sign1.substring(0, 1) == "_" || sign1.substring(0, 1) == "-") && (sign2.substring(0, 1) != "_" || sign2.substring(0, 1) != "-")) {
        return (1);
        // если первый производственный, а второй нет
    } else if ((sign1.substring(0, 1) != "_" || sign1.substring(0, 1) != "-") && (sign2.substring(0, 1) == "_" || sign2.substring(0, 1) == "-")) {
        return (-1);
        // если оба производственные
    } else if ((sign1.substring(0, 1) != "_" || sign1.substring(0, 1) != "-") && (sign2.substring(0, 1) != "_" || sign2.substring(0, 1) != "-")) {
        if (sign1 > sign2) return (1)
        else if (sign1 < sign2) return (-1)
        else return (0);
    }
}

// функция для сортировки маршрутов по номеру
export function routeNumSortFn(routeNum1, routeNum2) {
    //прежде всего нужно выделить буквенные и числовые частии номера маршрута.
    let routeNum1String = '';
    let routeNum1Int = '';
    for (let i = 0; i < routeNum1.length; i++) {
        if (Number.isInteger(Number(routeNum1.substring(i, i + 1)))) {
            routeNum1Int = routeNum1Int + routeNum1.substring(i, i + 1);
        } else {
            routeNum1String = routeNum1String + routeNum1.substring(i, i + 1);
        }
    }

    let routeNum2String = '';
    let routeNum2Int = '';
    for (let i = 0; i < routeNum2.length; i++) {
        if (Number.isInteger(Number(routeNum2.substring(i, i + 1)))) {
            routeNum2Int = routeNum2Int + routeNum2.substring(i, i + 1);
        } else {
            routeNum2String = routeNum2String + routeNum2.substring(i, i + 1);
        }
    }
    // сравниваем отделенные цифры
    if (Number(routeNum1Int) > Number(routeNum2Int)) return (1)
    else if (Number(routeNum1Int) < Number(routeNum2Int)) return (-1)
    else {
        //при равенстве цифр сравниваем буквы
        if (routeNum1String > routeNum2String) return (1)
        else if (routeNum1String < routeNum2String) return (-1)
        else return (0);
    }
}

// c
export function statInitFn(statInit1, statInit2) {
// сравниваем даты
    let date_index_1 = statInit1.date_as_int;
    let date_index_2 = statInit2.date_as_int;

    if (date_index_2 == 0)
        return -1
    if (date_index_1 == 0)
        return 1
// сравниваем наряд
    let naryad_1 = statInit1.naryad;
    let naryad_2 = statInit2.naryad;

    if (naryad_1 > naryad_2) return (1);
    else if (naryad_1 < naryad_2) return (-1);
    else {

        if (date_index_1 > date_index_2) return (1);
        else if (date_index_1 < date_index_2) return (-1);
        else {
            // сравниваем ТС
            let ts_id_1 = statInit1.ts_id;
            let ts_id_2 = statInit2.ts_id;
            if (ts_id_1 > ts_id_2) return (1);
            else if (ts_id_1 < ts_id_2) return (-1);
            else return 0;
        }
    }
}

//Конвертируем и возвращает пробег из метров в км
export function getLength(length) {
    if (length) {
        let result = length / 1000
        //Возвращаем отформатированный пробег
        return result.toFixed(3);
    } else {
        return 0;
    }
}

// функция возвращает сигнатуру в читаемом формате
export function getSignTitle(sign) {

    if (typeof (sign) !== 'string' || sign === '') return '';

    // извлекаем направление
    let direction = 'AB';
    if (sign.length >= 2 && (sign.substr(-2) === 'AB' || sign.substr(-2) === 'BA')) {
        if (sign.substr(-2) === 'BA')
            direction = 'BA';
        sign = sign.substr(0, sign.length - 2);
    }

    // извлекаем тип
    let raceKind = ''
    if (sign.length > 0 && (sign.substr(0, 1) === '_' || sign.substr(0, 1) === '-')) {
        raceKind = sign.substr(0, 1);
        sign = sign.substr(1);
    }

    // формируем результат
    if (raceKind === '_' && direction === 'AB') return sign + ' ' + '>>>'
    else if (raceKind === '_' && direction === 'BA') return sign + ' ' + '<<<'
    else if (raceKind === '-' && direction === 'AB') return sign + ' ' + '>>'
    else if (raceKind === '-' && direction === 'BA') return sign + ' ' + '<<'
    else if (direction === 'AB') return sign + ' ' + '>'
    else if (direction === 'BA') return sign + ' ' + '<'
    else return '';
}

// очищает объект от лишних свойств, начинающихся с '__'
export function clearObj(obj) {
    const newObj = {}
    Object.keys(obj).forEach(key => {
        if (!key.startsWith('__')) {
            newObj[key] = obj[key];
        }
    })
    return newObj
}

// очищает объект от лишних свойств, содержащих '__'
export function clearObjContains(obj) {
    const newObj = {}
    Object.keys(obj).forEach(key => {
        if (!key.includes('__')) {
            newObj[key] = obj[key];
        }
    })
    return newObj
}

// очищает массив объект от лишних свойств, начинающихся с '__'
export function clearArr(arr) {
    return arr.map(obj => clearObj(obj))
}

// очищает массив объект от лишних свойств, содержащих '__'
export function clearArrContains(arr) {
    return arr.map(obj => clearObjContains(obj))
}

export function getDist(lat_a, lng_a, lat_b, lng_b) {
    let pk = (180 / 3.14169);

    let a1 = lat_a / pk;
    let a2 = lng_a / pk;
    let b1 = lat_b / pk;
    let b2 = lng_b / pk;

    let t1 = Math.cos(a1) * Math.cos(a2) * Math.cos(b1) * Math.cos(b2);
    let t2 = Math.cos(a1) * Math.sin(a2) * Math.cos(b1) * Math.sin(b2);
    let t3 = Math.sin(a1) * Math.sin(b1);
    let tt = Math.acos(t1 + t2 + t3);

    return roundToFixed((6366 * tt));
}

// округляем до шестого знака после запятой, если округлилось с .0 удаляем ".0"
export function roundToFixed(number) {
    return number.toFixed(6).replace(/\.0$/, '');
}