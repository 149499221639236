<template>
  <formation-selector class="selector"
                      v-model="selectedFormationId"
                      @change="onFormationSelect"
                      style="margin-left: 0"
  ></formation-selector>
  <park-selector class="selector"
                 v-model="selectedParkId"
                 @change="onParkSelect"
  ></park-selector>
  <input-date v-if="isDateChoosersVisible" v-model="selectedStartDate" @change="onStartDateSelect"
              style="margin: 5px 5px 5px 5px; height: 26px"></input-date>
  <input-date v-if="isDateChoosersVisible" v-model="selectedEndDate" @change="onEndDateSelect"
              style="margin: 5px 5px 5px 5px; height: 26px"></input-date>

  <universal-selector v-if="this.$route.name === 'reports'"
                      class="selector"
                      v-model="selectedWeekday"
                      :itemList="weekdayList"
                      @change="onWeekdaySelect($event)"
  ></universal-selector>
  <universal-selector v-if="this.$route.name === 'reports'"
                      class="selector"
                      v-model="selectedInspPassFlowSqlVariant"
                      :itemList="inspPassFlowSqlVariantList"
                      @change="onInspPassFlowSqlVariantSelect($event)"
  ></universal-selector>

  <universal-selector v-if="this.$route.name === 'umkr'"
                      class="selector"
                      v-model="selectedUmkrViewVariant"
                      :itemList="umkrViewVariantList"
                      @change="onUmkrViewVariantSelect($event)"
  ></universal-selector>

  <universal-selector v-if="this.$route.name === 'processing'"
                      class="selector"
                      v-model="selectedProcessingViewVariant"
                      :itemList="processingViewVariantList"
                      @change="onProcessingViewVariantSelect($event)"
  ></universal-selector>

  <button @click="onClick"
          class="btn btn-info"
          style="margin: 5px 5px 5px 5px; padding: 0px 5px 0px 5px"
          type="button"
          :disabled="!selectedFormationId || !selectedParkId"
    >{{ $t("ParamsChooserPanel.Load") }}
  </button>

  <button v-if="this.$route.name === 'umkr' || this.$route.name === 'processing'"
          @click="onClickProcessingUmkr"
          class="btn btn-info"
          style="margin: 5px 5px 5px 5px; padding: 0px 5px 0px 5px"
          :disabled="isProcessingButtonDisabled"
  >{{ $t("ParamsChooserPanel.Process") }}
  </button>

  <button v-if="this.$route.name === 'umkr'"
          @click="onClickGetInspReisesByParks"
          class="btn btn-info"
          style="margin: 5px 5px 5px 5px; padding: 0px 5px 0px 5px"
          :disabled="isReportButtonDisabled"
  >{{ $t("ParamsChooserPanel.Report") }}
  </button>

  <button v-if="this.$route.name === 'umkr'"
          @click="onClickMapHistory"
          class="btn btn-info"
          style="padding: 0px 5px 0px 5px; margin: 5px 5px 5px auto;"
          :disabled="!this.selectedDateIndexUmkr && !this.selectedTsIdUmkr"
  >{{ $t("ParamsChooserPanel.History") }}
  </button>

  <button v-if="this.$route.name === 'tsNavHistory'"
          @click="onClickUMKR"
          class="btn btn-info"
          style="padding: 0px 5px 0px 5px; margin: 5px 5px 5px auto;"
          :disabled="!this.selectedDateIndexUmkr && !this.selectedTsIdUmkr"
  >{{ $t("ParamsChooserPanel.Back") }}
  </button>

  <button v-if="this.$route.name === 'processing'"
          @click="onClickShowProcessingResult"
          class="btn btn-info"
          style="padding: 0px 5px 0px 5px; margin: 5px 5px 5px auto;"
          :disabled="this.isShowResultButtonDisabled"
  >{{ $t("ParamsChooserPanel.ProcessingResult") }}
  </button>

  <!-- Спиннер -->
  <loading-modal
      v-if="isLoading"
      :loadingText="loadingText"
  ></loading-modal>

  <!-- Окно с прогрессбаром загрузки -->
  <loading-progressbar-modal
      v-if="isProgressbarVisible"
      :loadingText="loadingText"
      :progressbarValue="progressbarValue"
      :progressbarText="progressbarText"
  >
  </loading-progressbar-modal>

  <!-- Результаты обработки -->
  <processing-result-dialog
      v-if="isProcessingResultDialogVisible"
      @onCancel="onClickShowProcessingResult"
  >{{ errorMsg }}
  </processing-result-dialog>

  <insp-reises-by-parks-dialog
    v-if="isInspReisesByParksDialogVisible"
    @onCancel="onClickShowisInspReisesByParksDialog"
  >
  </insp-reises-by-parks-dialog>

  <!-- Подтверждение удаления -->
  <error-message-box
      v-if="isErrorDialogVisible"
      @onOK="onOk"
  >{{ errorMsg }}
  </error-message-box>

</template>

<script>
import {dateToND, stringToDate} from "@/lib";
import FormationSelector from "@/components/ui/custom/FormationSelector";
import ParkSelector from "@/components/ui/custom/ParkSelector";
import InputDate from "@/components/ui/custom/InputDate.vue";
import UniversalSelector from "@/components/ui/custom/UniversalSelector.vue";
import LoadingModal from "@/components/ui/custom/LoadingModal";
import ErrorMessageBox from "@/components/ui/custom/ErrorMessageBox";
import LoadingProgressbarModal from "@/components/ui/custom/LoadingProgressbarModal";
import ProcessingResultDialog from "@/components/processing_result/ProcessingResultDialog";
import InspReisesByParksDialog from "@/components/umkr/InspReisesByParksDialog";

export default {
  components: {
    InspReisesByParksDialog,
    ProcessingResultDialog,
    LoadingProgressbarModal,
    ErrorMessageBox,
    FormationSelector,
    ParkSelector,
    InputDate,
    UniversalSelector,
    LoadingModal
  },

  // props: {
  // selectedDateIndexUmkr: Number,
  // selectedTsIdUmkr: Number,
  // },

  data() {
    return {
      // текст для отображения загрузки
      loadingText: "",
      // загрузка
      isLoading: false,
      // выдимость модального окна с загрузкой
      isProgressbarVisible: false,
      // значение прогрессбара
      progressbarValue: 0,
      // текст для прогрессбара
      progressbarText: "",
      //параметры для processing
      PSGRS_COUNT_IN_UNDETECTED_REISES: 30,
      DISB_PSGRS: 2,
      // сообщение ошибки
      errorMsg: '',
      //таймер
      mainTimer: null,
      // обработанная "ячейка" таблицы processing
      processedValue: {},
      // Видимость диалога с результатами обработки
      isProcessingResultDialogVisible: false,
      // Видимость диалога "Отчет о перевозки пассажиров по парку"
      isInspReisesByParksDialogVisible: false,
      // результаты обработки
      procCarStatisticByPeriod: null,
    }
  },

  computed: {

    isErrorDialogVisible() {
      return this.errorMsg != "";
    },

    weekdayList() {
      return [
        {
          id: 0,
          name: this.$t("ParamsChooserPanel.WeekdayVariantList.AllDays") //"Все дни"
        },
        {
          id: 1,
          name: this.$t("ParamsChooserPanel.WeekdayVariantList.Weekday") //"Будни"
        },
        {
          id: 2,
          name: this.$t("ParamsChooserPanel.WeekdayVariantList.Weekend") //"Выходные"
        }
      ]
    },

    inspPassFlowSqlVariantList() {
      return [
        {
          id: 'Park',
          name: this.$t("ParamsChooserPanel.InspPassFlowSqlVariantList.Park") //"По паркам"
        },
        {
          id: 'ParkByDay',
          name: this.$t("ParamsChooserPanel.InspPassFlowSqlVariantList.ParkByDay") //"По паркам за день"
        },
        {
          id: 'Marsh',
          name: this.$t("ParamsChooserPanel.InspPassFlowSqlVariantList.Marsh") //"По маршрутам"
        },
        {
          id: 'MarshByDay',
          name: this.$t("ParamsChooserPanel.InspPassFlowSqlVariantList.MarshByDay") //"По маршрутам за день"
        },
        {
          id: 'Grafic',
          name: this.$t("ParamsChooserPanel.InspPassFlowSqlVariantList.Grafic") //"По графикам"
        },
        {
          id: 'GraficByDay',
          name: this.$t("ParamsChooserPanel.InspPassFlowSqlVariantList.GraficByDay") //"По графикам за день"
        }
      ]
    },

    umkrViewVariantList() {
      return [
        {
          id: 'Disb',
          name: this.$t("ParamsChooserPanel.UmkrViewVariantList.Disb") //'Дисбаланс'
        },
        {
          id: 'Transportation',
          name: this.$t("ParamsChooserPanel.UmkrViewVariantList.Transportation") //'Перевезено'
        },
        {
          id: 'Nariad',
          name: this.$t("ParamsChooserPanel.UmkrViewVariantList.Nariad") //'Наряд/Комментарий'
        },
        {
          id: 'ASMPP',
          name: this.$t("ParamsChooserPanel.UmkrViewVariantList.ASMPP") //'АСМПП/АСОП'
        },
        {
          id: 'InOut',
          name: this.$t("ParamsChooserPanel.UmkrViewVariantList.InOut") //'Вход/Выход'
        },
      ];
    },

    processingViewVariantList() {
      return [
        {
          id: 'Nariad',
          name: this.$t("ParamsChooserPanel.ProcessingViewVariantList.Nariad") //'Наряд'
        },
        {
          id: 'UndetectedReisCount',
          name: this.$t("ParamsChooserPanel.ProcessingViewVariantList.UndetectedReisCount") //'Кол-во нерасп. трасс'
        },
        {
          id: 'PsrgsInUndetectedReises',
          name: this.$t("ParamsChooserPanel.ProcessingViewVariantList.PsrgsInUndetectedReises") //'Вне рейсов/перев.'
        },
      ];
    },


    selectedFormationId() {
      return this.$store.getters['ref/getUserFormation'];
    },
    selectedParkId() {
      return this.$store.getters['ref/getUserPark'];
    },
    selectedStartDate() {
      return this.$store.getters['ref/getUserStartDate'];
    },
    selectedEndDate() {
      return this.$store.getters['ref/getUserEndDate'];
    },
    selectedWeekday() {
      return this.$store.getters['ref/getUserWeekday'];
    },
    selectedInspPassFlowSqlVariant() {
      return this.$store.getters['ref/getUserInspPassFlowSqlVariant'];
    },
    selectedUmkrViewVariant() {
      return this.$store.getters['ref/getUserTableUmkrViewVariant'];
    },
    selectedProcessingViewVariant() {
      return this.$store.getters['ref/getUserTableProcessingViewVariant'];
    },
    selectedDateIndexUmkr() {
      return this.$store.getters['ref/getUserUmkrDateIndex'];
    },
    selectedTsIdUmkr() {
      return this.$store.getters['ref/getUserUmkrTsId'];
    },
    selectedDateIndexProcessing() {
      return this.$store.getters['ref/getSelectedProcessingDateIndex']
    },
    selectedValueProcessing() {
      return this.$store.getters['ref/getSelectedProcessingValue']
    },

    isProcessingButtonDisabled() {
      if (this.$route.name === 'processing') {
        if (this.selectedDateIndexProcessing && this.selectedValueProcessing) {
          return false;
        } else {
          return true;
        }
      } else if (this.$route.name === 'umkr') {
        if (this.selectedDateIndexUmkr && this.selectedTsIdUmkr) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },

    isReportButtonDisabled() {
       if (this.$route.name === 'umkr') {
        if (this.$store.getters['umkr/getStatInitItog'].length != 0) {
          return false;
        } else {
          return true;
        }
      }
      return true;
    },

    isShowResultButtonDisabled() {
      if (this.selectedValueProcessing && this.processedValue
          && this.processedValue == this.selectedDateIndexProcessing) {
        return false;
      } else {
        return true;
      }
    },

    // видимость выборов дат
    isDateChoosersVisible(){
      return this.$route.name != 'references'
    },

  },

  methods: {

    async onFormationSelect() {
      this.$store.commit('ref/setUserPark', null);
      this.$store.commit('ref/setUserUmkrTsId', null);
      this.$store.commit('ref/setUserUmkrDateIndex', null);
      let formation = this.$store.getters['ref/getFormations'].find(value => value.tw_id == this.selectedFormationId);
      await this.$store.dispatch('ref/doGetParks', {formation: formation});
    },
    onParkSelect() {
      this.$store.commit('ref/setUserUmkrTsId', null);
      this.$store.commit('ref/setUserUmkrDateIndex', null);
    },
    onStartDateSelect(event) {
      this.$store.commit('ref/setUserStartDate', event.target.value);
    },
    onEndDateSelect(event) {
      this.$store.commit('ref/setUserEndDate', event.target.value);
    },
    onWeekdaySelect(event) {
      this.$store.commit('ref/setUserWeekday', event.target.value);
    },
    onInspPassFlowSqlVariantSelect(event) {
      this.$store.commit('ref/setUserInspPassFlowSqlVariant', event.target.value);
    },
    onUmkrViewVariantSelect(event) {
      this.$store.commit('ref/setUserUmkrViewVariant', event.target.value);
    },
    onProcessingViewVariantSelect(event) {
      this.$store.commit('ref/setUserProcessingViewVariant', event.target.value);
    },

    // нажата кнопка "Ок" в диалоге с ошибкой
    onOk() {
      this.errorMsg = "";
    },

    async onClick() {
      if (this.$route.name === 'reports') {
        await this.loadInspReisesPassFlowData();
      } else if (this.$route.name === 'umkr' || this.$route.name === 'processing') {
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedParkId);
        await this.$store.dispatch('umkr/doGetActiveASMPPTSByPark', {curPark: park});
        await this.$store.dispatch('rep/doGetApcTypes', {curPark: park})

        if (this.$route.name === 'umkr') {
          await this.reloadUmkrData(park);
        } else {
          await this.loadProcessingData(park);
        }
      } else if (this.$route.name === 'references') {

        // делаем массив маршрутов пустым
        this.$store.commit('ref/setMarshes', []);
        // открываем справочник маршрутов
        this.$store.commit('ref/setSelectedDir', 'marshes');
        // сбрасываем выбранный маршрут
        this.$store.commit('ref/setSelectedMarsh', null);
        // сбрасываем выбранный маршрут для вкладки
        this.$store.commit('ref/setSelectedMarshForTab', null);
        // сбрасываем загруженные периоды действия
        this.$store.commit('ref/setMarshVariants', []);
        // сбрасываем выбранный период действия
        this.$store.commit('ref/setSelectedMarshVariant', null);
        // сбрасываем выбранный период действия для вкладки
        this.$store.commit('ref/setSelectedMarshVariantForTab', null);
        // сбрасываем загруженные трассы
        this.$store.commit('ref/setRaces', []);
        // сбрасываем выбранную трассу
        this.$store.commit('ref/setSelectedRace', null);
        // сбрасываем выбранную трассу действия для вкладки
        this.$store.commit('ref/setSelectedRaceForTab', null);
        // сбрасываем загруженные остановки трассы
        this.$store.commit('ref/setRaceCards', []);
        // сбрасываем выбранную остановку трассы
        this.$store.commit('ref/setSelectedRaceCard', null);
        // сбрасываем выбранную остановку
        this.$store.commit('ref/setSelectedParkStation', null);

        // получаем id выбранного парка
        let parkId = this.$store.getters['ref/getUserPark'];
        // получаем выбранный парк
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == parkId);
        // по выбранному парку получаем типы транспорта
        await this.$store.dispatch('ref/doGetTransTypes', {park: park});
        // по выбранному парку получаем тарифные зоны
        await this.$store.dispatch('ref/doGetTarifZones', {park: park});
        this.$store.getters['ref/getTransTypes'].map(async trtype => {
          await this.$store.dispatch('ref/doGetMarshes', {
            park: park,
            transportType: trtype,
          });
        });
      }
    },

    async loadInspReisesPassFlowData() {
      this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', this.selectedInspPassFlowSqlVariant);
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedParkId);
      let parkAr = [];
      parkAr.push(park);
      let payload = {
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
        parkArr: parkAr,
        weekdayIndex: this.selectedWeekday,
        inspPassFlowSqlVariant: this.selectedInspPassFlowSqlVariant
      };
      // обнуляем историю
      this.$store.commit('ref/setHistoryArrStep', []);
      // добавляем загруженные данные как начало истории навигации при посощи выпадающего меню
      this.$store.commit('ref/addHistoryArrStep', payload);
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
    },

    async onClickGetInspReisesByParks() {
      this.isLoading = true;
      this.loadingText = this.$t("ParamsChooserPanel.Message_getInspReisesByParks") //'Получение данных...';
      try {
        let parksArr = [];
        let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedParkId);
        parksArr.push(park);
        this.$store.dispatch('umkr/doClearCacheInspReisesByParks');
        for (let i = dateToND(stringToDate(this.selectedStartDate)); i <= dateToND(stringToDate(this.selectedEndDate)); i++){
          await this.$store.dispatch('umkr/doFetchInspReisesByParks', {
            parksArr: parksArr,
            dateIndex: i,
          });
        }
        this.isInspReisesByParksDialogVisible = true;
      } finally {
        this.loadingText = "";
        this.isLoading = false;
      }
    },

    async onClickProcessingUmkr() {
      if (this.selectedDateIndexUmkr && this.$route.name === 'umkr') {
        this.isLoading = true;
        this.loadingText = this.$t("ParamsChooserPanel.Message_processingUmkr") //'Обработка первичных данных...';
        try {
          const jsonTSArr = [];
          const jsonCommArr = [];
          const jsonAsopArr = [];
          let statInitItog = this.$store.getters['umkr/getStatInitItog'];
          statInitItog.map(value => {
            let ts = this.$store.getters['umkr/getTSOriginal'].find(ts => value.ts_id == ts.ts_id);
            jsonTSArr.push(ts);
            jsonCommArr.push(value.info[this.selectedDateIndexUmkr].comm);
            jsonAsopArr.push(value.info[this.selectedDateIndexUmkr].asop);
          })
          let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedParkId);
          await this.$store.dispatch('umkr/doСalcCarUMKStatistic', {
            curPark: park,
            dateIndex: this.selectedDateIndexUmkr,
            jsonTSArr: jsonTSArr,
            jsonCommArr: jsonCommArr,
            jsonAsopArr: jsonAsopArr
          });
          this.loadingText = this.$t("ParamsChooserPanel.Message_importNariad") //'Импорт наряда...';
          await this.$store.dispatch('umkr/updateTSNariadInfo', {
            curPark: park,
            dateIndex: this.selectedDateIndexUmkr,
            jsonTSArr: jsonTSArr,
          });
          await this.reloadUmkrData(park);
        } finally {
          this.isLoading = false;
          this.loadingText = "";
        }
      } else if (this.$route.name === 'processing') {
        this.isProgressbarVisible = true;
        this.progressbarValue = 0;
        this.loadingText = this.$t("ParamsChooserPanel.Message_procByNariad") //'Обработка по наряду';
        try {
          let dateIndexMin = this.selectedDateIndexProcessing;
          let dateIndexMax = this.selectedDateIndexProcessing;
          let jsonTS = this.$store.getters['umkr/getTSOriginal'].find(ts => this.selectedValueProcessing.ts_id == ts.ts_id);
          // делаем запрс getStatInit_byTS  ProcThread строка 213
          let diagnosticInfo = await this.$store.dispatch('umkr/doGetStatInitByTS', {
            dateIndexMin: dateIndexMin,
            dateIndexMax: dateIndexMax,
            jsonTS: jsonTS
          });
          let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedParkId);
          if (diagnosticInfo.info[dateIndexMin].statInitId == 0) {
            await this.$store.dispatch('umkr/doСalcCarUMKStatisticByTs', {
              jsonPark: park,
              jsonTSArr: [jsonTS],
              dateIndex: this.selectedDateIndexProcessing,
              comm: ""
            });
            await this.$store.dispatch('proc/doGetDispNariadByTs', {
              jsonPark: park,
              dispTsID: jsonTS.ts_id,
              dateIndex: this.selectedDateIndexProcessing,
            });
          }
          // взводим таймер обновление прогресбара
          // запускаем таймер
          this.mainTimer = setInterval(() => {
            this.updateProgressBarValues();
          }, 1000);
          // запуск обработки по наряду
          let error = await this.processingCalcWithProgressbarUpdate(jsonTS, park, dateIndexMin, dateIndexMax);
          // убиваем таймер
          if (this.mainTimer) {
            clearInterval(this.mainTimer);
            this.mainTimer = null;
            this.isProgressbarVisible = false;
          }
          if (error != null && error != "") {
            if (error.includes("==") && error.split("==").length == 2) {
              let msg = error.split("==");
              this.errorMsg = msg[1];
              // } else if (error.includes("-") && error.split("-").length == 2){
              //   let msg = error.split("-");
              //   this.errorMsg = msg[1];
              // }
            } else {
              this.errorMsg = error;
            }
          } else if (error == "") {

            // если расчет прошел без ошибок необходимо занести данные по какой ячейке это произошло в соответсвующую сущность
            this.processedValue = this.selectedDateIndexProcessing;
            this.isLoading = true;
            // запрашиваем результаты обработки
            let procCarStatisticByPeriod = await this.$store.dispatch('proc/doGetProcStatistic');
            if(procCarStatisticByPeriod)
              this.procCarStatisticByPeriod = procCarStatisticByPeriod;
            // удаляем данные на сервере
            await this.$store.dispatch('proc/doReleaseSessionInfo');
            this.isLoading = false;
          }

        } finally {
          this.loadingText = "";
        }
      }
    },

    async reloadUmkrData(park) {
      let ts = this.$store.getters['umkr/getTS'];
      //формируем массив из ts_id
      let ts_id_arr = [];
      ts.map(value => {
        ts_id_arr.push(value.ts_id);
      })
      //загружаем данные StatInit
      await this.$store.dispatch('umkr/doGetStatInitByTSArray', {
        curPark: park,
        tsArr: ts_id_arr,
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
      });

      //формируем итоговый массив со всеми данными
      let statInitAr = this.$store.getters['umkr/getStatInitByTs'];
      let resultArr = [];
      if (statInitAr.length != 0) {
        ts.map(value => {
          let statInit = statInitAr.find(statInit => value.ts_id == statInit.ts_id);
          if (statInit) {
            value['info'] = statInit['info'];
          }
          let apcType = this.$store.getters['rep/getApcTypes'].find(apc => apc.apcType_id == value.apcTypeID);
          if (apcType) {
            value.apcTypeName = apcType.apcTypeName;
          } else {
            value.apcTypeName = '-';
          }
          resultArr.push(value);
        })
      }
      this.$store.commit('umkr/setStatInitItog', resultArr);
    },

    async loadProcessingData(park) {
      // получаем ts id arr
      let ts_id_arr = [];
      this.$store.getters['umkr/getTS'].map(value => {
        ts_id_arr.push(value.ts_id);
      })
      await this.getSysParams(park);

      await this.$store.dispatch('umkr/doGetProcessedStatInfo', {
        identityID: park.identity,
        jsonTS_IDs: ts_id_arr,
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
      });

      //загружаем данные StatInit
      await this.$store.dispatch('umkr/doGetStatInitByTSArray', {
        curPark: park,
        tsArr: ts_id_arr,
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
      });

    },

    async getSysParams(park) {
      let sysParams = await this.$store.dispatch('umkr/doGetSystemParams', {identity: park.identity});
      if (sysParams) {
        sysParams.map(param => {
          if (param.key == 'passengersLimitInUndetectedReis') {
            if (param.value) {
              this.PSGRS_COUNT_IN_UNDETECTED_REISES = param.value;
            } else {
              this.PSGRS_COUNT_IN_UNDETECTED_REISES = 200;
            }
          } else if (param.key == 'passergersDisbLimit') {
            if (param.value) {
              this.DISB_PSGRS = param.value;
            } else {
              this.DISB_PSGRS = 20;
            }
          }
        })
      }
    },

    // обновляем значения прогрессбара
    async updateProgressBarValues() {
      // перезапрашиваются данные прогресбара
      let progValue = await this.$store.dispatch('proc/doGetProgrVal');
      this.progressbarValue = progValue.value;
      this.progressbarText = progValue.text;
    },

    // переходим на страницу истории движения выбранного ТС
    onClickMapHistory() {
      this.$router.push('/umkr/' + this.selectedFormationId + '/' + this.selectedParkId + '/' + this.selectedTsIdUmkr + '/' + this.selectedDateIndexUmkr + '/tsNavHistory');
    },

    onClickUMKR() {
      this.$router.push('/umkr');
    },

    // запуск обработки по наряду с
    async processingCalcWithProgressbarUpdate(jsonTS, park, dateIndexMin, dateIndexMax,) {
      // в java-клиенте данные параметры считываются из файла, если его нет,то хард-код данных ниже
      let params = {
        max_speed: 110,
        time_between_nav: 300,
        len2pointAsKm: 0.01,
        reis_km: 5,
        time_undetected_reis: 900,
        time_leave_reis: 480,
        time_stop_reis: 3720,
        map_lat: 0.0,
        map_lon: 0.0,
        map_zoom: 0,
        reload_marsh_net: false
      };

      let error = await this.$store.dispatch('proc/doRunNavLinkProc', {
        jsonTS: jsonTS,
        jsonPark: park,
        dateIndexMin: dateIndexMin,
        dateIndexMax: dateIndexMax,
        procByNariad: false,
        idDemo: false,
        jsonProcUserParams: params
      });
      return error
    },

    // меняем видимость диалога с результатами обработки
    onClickShowProcessingResult() {
      this.isProcessingResultDialogVisible = !this.isProcessingResultDialogVisible;
    },

    // меняем видимость диалога  "Отчет о перевозки пассажиров по парку"
    onClickShowisInspReisesByParksDialog() {
      this.isInspReisesByParksDialogVisible = !this.isInspReisesByParksDialogVisible;
    },


  },

}
</script>

<style scoped>
.selector {
  margin: 5px 5px 5px 5px;
  height: 26px
}
</style>