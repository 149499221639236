<template>
  <table-component :columnList="columnList" :rowList="rowList"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent";
import {getDateWitoutTimeZone, padStr} from "@/lib";
export default {
  name: "ProcessingResultReisesTable",
  props: ['selectedReis'],
  components: {TableComponent},
  computed: {
    // колонки таблицы
    columnList(){
      return [
        {
          columnName: "raceStationOrderby",
          columnTitle: this.$t("ProcessingResultStationsTable.Order"),
          isVisible: true
        },
        {
          columnName: "__stationName",
          columnTitle: this.$t("ProcessingResultStationsTable.Station"),
          isVisible: true
        },
        {
          columnName: "__timeInTable",
          columnTitle: this.$t("ProcessingResultStationsTable.Time"),
          isVisible: true
        },
        {
          columnName: "__station_pIn",
          columnTitle: this.$t("ProcessingResultStationsTable.BalanceIn"),
          isVisible: true
        },
        {
          columnName: "__station_pOut",
          columnTitle: this.$t("ProcessingResultStationsTable.BalanceOut"),
          isVisible: true
        },
        {
          columnName: "__station_pInside",
          columnTitle: this.$t("ProcessingResultStationsTable.BalanceInside"),
          isVisible: true
        },
        {
          columnName: "pIn",
          columnTitle: this.$t("ProcessingResultStationsTable.UnbalanceIn"),
          isVisible: true
        },
        {
          columnName: "pOut",
          columnTitle: this.$t("ProcessingResultStationsTable.UnbalanceOut"),
          isVisible: true
        },
        {
          columnName: "pInside",
          columnTitle: this.$t("ProcessingResultStationsTable.UnbalanceInside"),
          isVisible: true
        },
        {
          columnName: "__comm",
          columnTitle: this.$t("ProcessingResultStationsTable.Comment"),
          isVisible: true
        },
      ]
    },
    // строки таблицы
    rowList(){
      if(this.selectedReis) {
        let stations = this.selectedReis.stations;
        let balStations = this.selectedReis.balStations;
        let ind = 0;
        return stations.map(value => {
          value.__id = value.raceStationOrderby;
          value.__stationName = value.stationParams.stationName;
          let dateInTableWithoutTimeZone = getDateWitoutTimeZone(new Date(value.stationParams.timeInTable))
          value.__timeInTable = padStr(dateInTableWithoutTimeZone.getHours()) + ':' +  padStr(dateInTableWithoutTimeZone.getMinutes()) + ':' +  padStr(dateInTableWithoutTimeZone.getSeconds());
          value.__station_pIn = balStations[ind].pIn;
          value.__station_pOut = balStations[ind].pOut;
          value.__station_pInside = balStations[ind].pInside;
          if (value.comm == this.$t("ProcessingResultStationsTable.PastBy")) {
            value.__comm = this.$t("ProcessingResultStationsTable.Interpolation");
          } else {
            value.__comm = value.comm;
          }
          ind++;
          return value;
        })
      } else {
        return [];
      }
    }
  }
}
</script>

<style scoped>

</style>