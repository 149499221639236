<template>
  <table-component :columnList="columns" :rowList="rows" :contextMenuItems="contextMenuItems"
                   @setSelectedRow="setSelectedRow"></table-component>
</template>

<script>
import TableComponent from "@/components/ui/custom/TableComponent.vue";
import {ndToDate, getDateStringFromDateTime, dateToND, stringToDate} from "@/lib";

export default {
  data() {
    return {
      // переменная в которой хранится строка для которой открыли контекстное меню
      selectedRow: null,
    }
  },
  components: {
    TableComponent,
  },

  computed: {

    tableColumnVariant() {
      return this.$store.getters['ref/getUserInspPassFlowTableColumnVariant'];
    },

    columns() {
      let resultArr = [];
      resultArr.push({
        columnName: "parkName",
        columnTitle: this.$t("ReportsPage.ParkTitle"), //"Парк",
        columnTooltip: this.$t("ReportsPage.ParkTooltip"), //"Название предприятия",
        isVisible: true
      });
      if (this.tableColumnVariant !== 'Park' && this.tableColumnVariant !== 'ParkByDay') {
        resultArr.push({
          columnName: "transType",
          columnTitle: this.$t("ReportsPage.TransTypeTitle"), //"Тип транспорта",
          columnTooltip: this.$t("ReportsPage.TransTypeTooltip"), //"Тип транспорта",
          isVisible: true
        });
        resultArr.push({
          columnName: "marshName",
          columnTitle: this.$t("ReportsPage.MarshTitle"), //"Маршрут",
          columnTooltip: this.$t("ReportsPage.MarshTooltip"), //"Маршрут",
          isVisible: true
        });
      }
      if (this.tableColumnVariant !== 'Park' && this.tableColumnVariant !== 'Marsh' && this.tableColumnVariant !== 'MarshByDay' && this.tableColumnVariant !== 'ParkByDay') {
        resultArr.push({
          columnName: "grafic",
          columnTitle: this.$t("ReportsPage.GraficTitle"), //"График",
          columnTooltip: this.$t("ReportsPage.GraficTooltip"), //"График",
          isVisible: true
        });
      }
      if (this.tableColumnVariant !== 'Park' && this.tableColumnVariant !== 'Marsh' && this.tableColumnVariant !== 'Grafic') {
        resultArr.push({
          columnName: "date_index",
          columnTitle: this.$t("ReportsPage.DateTitle"), //"Дата",
          columnTooltip: this.$t("ReportsPage.DateTooltip"), //"Дата",
          isVisible: true
        });
        resultArr.push({
          columnName: "weekday",
          columnTitle: this.$t("ReportsPage.WeekdayTitle"), //"День нед.",
          columnTooltip: this.$t("ReportsPage.WeekdayTooltip"), //"День недели",
          isVisible: true
        });
      }
      resultArr.push({
        columnName: "reis_count",
        columnTitle: this.$t("ReportsPage.ReisCountTitle"), //"Кол-во рейсов",
        columnTooltip: this.$t("ReportsPage.ReisCountTooltip"), //"Количество рейсов",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.reis_count) {
            return Number(row.reis_count).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "carriage_sum",
        columnTitle: this.$t("ReportsPage.CarriageSumTitle"), //"Сум. перевозка",
        columnTooltip: this.$t("ReportsPage.CarriageSumTooltip"), //"Суммарная перевозка, чел.",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.carriage_sum) {
            return Number(row.carriage_sum).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      if (this.tableColumnVariant !== 'MarshByDay' && this.tableColumnVariant !== 'GraficByDay' && this.tableColumnVariant !== 'ParkByDay') {
        resultArr.push({
          columnName: "carriage_avg_day",
          columnTitle: this.$t("ReportsPage.CarriageAvgDayTitle"), //"Сред. перевозка",
          columnTooltip: this.$t("ReportsPage.CarriageAvgDayTooltip"), //"Средняя перевозка за день, чел.",
          isVisible: true,
          type: 'html',
        displayFn(row) {
            if (row.carriage_avg_day) {
              return Number(row.carriage_avg_day).toLocaleString('ru-RU');
            }
            return 0
          },
        });
      }
      resultArr.push({
        columnName: "carriage_avg_day_ts",
        columnTitle: this.$t("ReportsPage.CarriageAvgDayTsTitle"), //"Сред. перевозка по ТС",
        columnTooltip: this.$t("ReportsPage.CarriageAvgDayTsTooltip"), //"Средняя перевозка по ТС, чел.",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.carriage_avg_day_ts) {
            return Number(row.carriage_avg_day_ts).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "carriage_avg_reis",
        columnTitle: this.$t("ReportsPage.CarriageAvgReisTitle"), //"Сред. перевозка за рейс",
        columnTooltip: this.$t("ReportsPage.CarriageAvgReisTooltip"), //"Средняя перевозка за рейс, чел.",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.carriage_avg_reis) {
            return Number(row.carriage_avg_reis).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "paid_sum",
        columnTitle: this.$t("ReportsPage.PaidSumTitle"), //"Сум. оплата",
        columnTooltip: this.$t("ReportsPage.PaidSumTooltip"), //"Суммарная оплата",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.paid_sum) {
            return Number(row.paid_sum).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      if (this.tableColumnVariant !== 'ParkByDay' && this.tableColumnVariant !== 'MarshByDay' && this.tableColumnVariant !== 'GraficByDay') {
        resultArr.push({
          columnName: "paid_avg_day",
          columnTitle: this.$t("ReportsPage.PaidAvgDayTitle"), //"Сред. оплата",
          columnTooltip: this.$t("ReportsPage.PaidAvgDayTooltip"), //"Средняя оплата за день",
          isVisible: true,
          type: 'html',
        displayFn(row) {
            if (row.paid_avg_day) {
              return Number(row.paid_avg_day).toLocaleString('ru-RU');
            }
            return 0
          },
        });
      }
      resultArr.push({
        columnName: "paid_avg_day_ts",
        columnTitle: this.$t("ReportsPage.PaidAvgDayTsTitle"), //"Сред. оплата по ТС",
        columnTooltip: this.$t("ReportsPage.PaidAvgDayTsTooltip"), //"Средняя оплата по ТС",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.paid_avg_day_ts) {
            return Number(row.paid_avg_day_ts).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "paid_avg_reis",
        columnTitle: this.$t("ReportsPage.PaidAvgReisTitle"), //"Сред. оплата за рейс",
        columnTooltip: this.$t("ReportsPage.PaidAvgReisTooltip"), //"Средняя оплата за рейс",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.paid_avg_reis) {
            return Number(row.paid_avg_reis).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "trip_length_avg",
        columnTitle: this.$t("ReportsPage.TripLengthAvgTitle"), //"L сред.",
        columnTooltip: this.$t("ReportsPage.TripLengthAvgTooltip"), //"Средняя длина поездки",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.trip_length_avg) {
            return Number(row.trip_length_avg).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "psgrs_km",
        columnTitle: this.$t("ReportsPage.PsgrsKmTitle"), //"Пасс*км",
        columnTooltip: this.$t("ReportsPage.PsgrsKmTooltip"), //"Пасс*км",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.psgrs_km) {
            return Number(row.psgrs_km).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "kpd_km",
        columnTitle: this.$t("ReportsPage.KpdKmTitle"), //"КПД/км",
        columnTooltip: this.$t("ReportsPage.KpdKmTooltip"), //"КПД/км",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.kpd_km) {
            return Number(row.kpd_km).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      resultArr.push({
        columnName: "kpd_hour",
        columnTitle: this.$t("ReportsPage.KpdHourTitle"), //"КПД/час",
        columnTooltip: this.$t("ReportsPage.KpdHourTooltip"), //"КПД/час",
        isVisible: true,
        type: 'html',
        displayFn(row) {
          if (row.kpd_hour) {
            return Number(row.kpd_hour).toLocaleString('ru-RU');
          }
          return 0
        },
      });
      return resultArr;
    },

    rows() {
      let resultArr = [];
      let statInitAr = this.$store.getters['rep/getInspReisesPassFlowIndicators'];
      if (statInitAr.length != 0) {
        let obj = null;
        let dateString = null;
        statInitAr.map((value, index) => {
          dateString = ndToDate(value.date_index),
              obj = {
                __id: index,
                parkId: value.parkId,
                parkName: this.$store.getters['ref/getParks'].find(park => park.parkId == value.parkId).parkTitleLong,
                transType: value.transType,
                mr_id: value.mr_id,
                marshName: value.marshName,
                grafic: value.grafic,
                date_index: getDateStringFromDateTime(dateString.toString()),
                weekday: this.getWeekdayString(value.weekday),
                //показатели пассажиропотока
                reis_count: value.reis_count,
                carriage_sum: value.carriage_sum,
                carriage_avg_day: this.roundToFixed(value.carriage_avg_day),
                carriage_avg_day_ts: this.roundToFixed(value.carriage_avg_day_ts),
                carriage_avg_reis: this.roundToFixed(value.carriage_avg_reis),

                paid_sum: this.roundToFixed(value.paid_sum),
                paid_avg_day: this.roundToFixed(value.paid_avg_day),
                paid_avg_day_ts: this.roundToFixed(value.paid_avg_day_ts),
                paid_avg_reis: this.roundToFixed(value.paid_avg_reis),

                trip_length_avg: this.roundToFixed(value.trip_length_avg),
                psgrs_km: this.roundToFixed(value.psgrs_km),
                kpd_km: this.roundToFixed(value.kpd_km),
                kpd_hour: this.roundToFixed(value.kpd_hour)
              };
          resultArr.push(obj);
        });
      } else if (this.$store.getters['ref/getHistoryArr'].length > 1) {
        resultArr.push({
          __id: 0,
          parkId: "",
          parkName: "",
          transType: "",
          mr_id: "",
          marshName: "",
          grafic: "",
          date_index: "",
          weekday: "",
          //показатели пассажиропотока
          reis_count: "",
          carriage_sum: "",
          carriage_avg_day: "",
          carriage_avg_day_ts: "",
          carriage_avg_reis: "",

          paid_sum: "",
          paid_avg_day: "",
          paid_avg_day_ts: "",
          paid_avg_reis: "",

          trip_length_avg: "",
          psgrs_km: "",
          kpd_km: "",
          kpd_hour:""
        });
      }
      return resultArr;
    },

    contextMenuItems() {
      let arr = [];
      // для данных значений вообще не добавляем
      if (this.tableColumnVariant == 'ParkByDay') {
        arr.push(
            {
              label: this.$t("ReportsPage.ButtonBack"),
              onClick: () => {
                // this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Park');
                // this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Park');
                this.returnToPreviousTable('Park');
              }
            }
        )
      } else if (this.tableColumnVariant == 'MarshByDay') {
        arr.push(
          {
            label: this.$t("ReportsPage.ButtonBack"),
            onClick: () => {
              // this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Marsh');
              // this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Marsh');
              this.returnToPreviousTable('Marsh');
            }
          }
        )
      } else if (this.tableColumnVariant == 'GraficByDay') {
        arr.push(
          {
            label: this.$t("ReportsPage.ButtonBack"),
            onClick: () => {
              // this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Grafic');
              // this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Grafic');
              this.returnToPreviousTable('Grafic');
            }
          }
        )
      } else if (this.tableColumnVariant == 'Park') {
        arr.push(
            {
              label: this.$t("ReportsPage.ButtonByDate"),
              onClick: () => {
                this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'ParkByDay');
                this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'ParkByDay');
                this.getParkByDay();
              }
            },
            {
              label: this.$t("ReportsPage.ButtonByRoute"),
              onClick: () => {
                this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Marsh');
                this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Marsh');
                this.getMarsh();
              }
            }
        )
      } else if (this.tableColumnVariant == 'Marsh') {
        arr.push(
            {
              label: this.$t("ReportsPage.ButtonByDate"),
              onClick: () => {
                this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'MarshByDay');
                this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'MarshByDay');
                this.getMarshByDay();
              }
            },
            {
              label: this.$t("ReportsPage.ButtonByGrafic"),
              onClick: () => {
                this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Grafic');
                this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Grafic');
                this.getGrafic();
              }
            },
            {
              label: this.$t("ReportsPage.ButtonBack"),
              onClick: () => {
                // this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Park');
                // this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Park');
                this.returnToPreviousTable('Park');
              }
            }
        )
      } else if (this.tableColumnVariant == 'Grafic') {
        arr.push(
            {
              label: this.$t("ReportsPage.ButtonByDate"),
              onClick: () => {
                this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'GraficByDay');
                this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'GraficByDay');
                this.getGraficByDay();
              }
            },
            {
              label: this.$t("ReportsPage.ButtonBack"),
              onClick: () => {
                // this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', 'Marsh');
                // this.$store.commit('ref/setUserInspPassFlowSqlVariant', 'Marsh');
                this.returnToPreviousTable('Marsh');
              }
            }
        )
      }
      return arr;
    },

    selectedStartDate() {
      return this.$store.getters['ref/getUserStartDate'];
    },
    selectedEndDate() {
      return this.$store.getters['ref/getUserEndDate'];
    },
    selectedWeekday() {
      return this.$store.getters['ref/getUserWeekday'];
    },

  },

  methods: {

    setSelectedRow(row) {
      this.selectedRow = row;
    },

    // нажата кнопка в выпадающем меню "По датам" при загруженных данных по паркам
    async getParkByDay() {
      // получаем из выбранной строки park_id чтобы сделать запрос по парку из строки
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedRow.parkId);
      let parkAr = [];
      parkAr.push(park);
      let payload = {
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
        parkArr: parkAr,
        weekdayIndex: this.selectedWeekday,
        inspPassFlowSqlVariant: 'ParkByDay'
      };
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
      this.$store.commit('ref/addHistoryArrStep', payload);
    },

    // нажата кнопка в выпадающем меню "По маршрутам" при загруженных данных по паркам
    async getMarsh() {
      // получаем из выбранной строки park_id чтобы сделать запрос по парку из строки
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedRow.parkId);
      let parkAr = [];
      parkAr.push(park);
      let payload = {
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
        parkArr: parkAr,
        weekdayIndex: this.selectedWeekday,
        inspPassFlowSqlVariant: 'Marsh'
      };
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
      this.$store.commit('ref/addHistoryArrStep', payload);
    },

    // нажата кнопка в выпадающем меню "По датам" при загруженных данных по маршрутам
    async getMarshByDay() {
      // получаем из выбранной строки park_id чтобы сделать запрос по парку из строки
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedRow.parkId);
      let parkAr = [];
      parkAr.push(park);
      let payload = {
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
        parkArr: parkAr,
        weekdayIndex: this.selectedWeekday,
        inspPassFlowSqlVariant: 'MarshByDay',
        filterValue: this.selectedRow.mr_id,
      };
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
      this.$store.commit('ref/addHistoryArrStep', payload);
    },

    // нажата кнопка в выпадающем меню "По датам" при загруженных данных по графикам
    async getGraficByDay() {
      // получаем из выбранной строки park_id чтобы сделать запрос по парку из строки
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedRow.parkId);
      let parkAr = [];
      parkAr.push(park);
      let payload = {
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
        parkArr: parkAr,
        weekdayIndex: this.selectedWeekday,
        inspPassFlowSqlVariant: 'GraficByDay',
        filterValue: this.selectedRow.mr_id + ',' + this.selectedRow.grafic,
      }
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
      this.$store.commit('ref/addHistoryArrStep', payload);
    },


    // нажата кнопка в выпадающем меню "По графикам" при загруженных данных по маршрутам
    async getGrafic() {
      // получаем из выбранной строки park_id чтобы сделать запрос по парку из строки
      let park = this.$store.getters['ref/getParks'].find(value => value.parkId == this.selectedRow.parkId);
      let parkAr = [];
      parkAr.push(park);
      let payload = {
        startDate: dateToND(stringToDate(this.selectedStartDate)),
        endDate: dateToND(stringToDate(this.selectedEndDate)),
        parkArr: parkAr,
        weekdayIndex: this.selectedWeekday,
        inspPassFlowSqlVariant: 'Grafic',
        filterValue: this.selectedRow.mr_id,
      };
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
      this.$store.commit('ref/addHistoryArrStep', payload);
    },

    async returnToPreviousTable(InspPassFlowSqlVariant) {
      // берем предыдущий payload из "массива"
      let payload = this.$store.getters['ref/getHistoryArr'][this.$store.getters['ref/getHistoryArr'].length - 2];
      if (payload == null) {
        return;
      }
      this.$store.commit('ref/setUserInspPassFlowTableColumnVariant', InspPassFlowSqlVariant);
      this.$store.commit('ref/setUserInspPassFlowSqlVariant', InspPassFlowSqlVariant);
      // удаляем последнюю запись из массива
      console.error(payload);
      this.$store.commit('ref/deleteHistoryArrStep');
      await this.$store.dispatch('rep/doGetInspReisesPassFlowIndicators', payload);
    },

    // округляем до первого знака после запятой, если округлилось с .0 удаляем ".0"
    roundToFixed(number) {
      return number.toFixed(1).replace(/\.0$/, '');
    },

    getWeekdayString(weekday) {
      switch (weekday) {
        case 1:
          return this.$t("ReportsPage.WeekdayMon"); //"Пн";
        case 2:
          return this.$t("ReportsPage.WeekdayTue"); //"Вт";
        case 3:
          return this.$t("ReportsPage.WeekdayWed"); //"Ср";
        case 4:
          return this.$t("ReportsPage.WeekdayThu"); //"Чт";
        case 5:
          return this.$t("ReportsPage.WeekdayFri"); //"Пт";
        case 6:
          return this.$t("ReportsPage.WeekdaySat"); //"Сб";
        case 7:
          return this.$t("ReportsPage.WeekdaySun"); //"Вс";
        default:
          return "";
      }
    }

  }
}
//
// function getWeekdayString(weekday) {
//   console.log(this.$t("ReportsPage.ButtonByDate"))
//   switch (weekday) {
//     case 1:
//       return this.$t("ReportsPage.WeekdayMon"); //"Пн";
//     case 2:
//       return this.$t("ReportsPage.WeekdayTue"); //"Вт";
//     case 3:
//       return this.$t("ReportsPage.WeekdayWed"); //"Ср";
//     case 4:
//       return this.$t("ReportsPage.WeekdayThu"); //"Чт";
//     case 5:
//       return this.$t("ReportsPage.WeekdayFri"); //"Пт";
//     case 6:
//       return this.$t("ReportsPage.WeekdaySat"); //"Сб";
//     case 7:
//       return this.$t("ReportsPage.WeekdaySun"); //"Вс";
//     default:
//       return "";
//   }
// }

</script>

<style scoped>
table {
  font-size: 0.8rem
}
</style>